import React from "react";
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
  const socialData = [
    { link: "https://www.facebook.com/tecciance", name: "Facebook." },
    // {link: "#0", name: "Instagram."},
    { link: "https://www.linkedin.com/company/tecciance/", name: "Linkedin." },
    // {link: "#0", name: "Twitter."}
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Sri Lanka</h5>
          <p>
          69 Janadhipathi Mawatha,
            <br />  Colombo 00100, <br />
            Sri Lanka
          </p>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+94 (77)398 5557"
              data-hover-text="+94 (77)398 5557"
            >
              +94 (77) 398 5557
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@tecciance.com"
              data-hover-text="info@tecciance.com"
            >
              info@tecciance.com
            </a>
          </p>
        </div>

        <div className="nav-content mt-30">
          {/* <h5 className="sm-title-block mb-10">Contact</h5> */}
          {/* <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+94 (77)398 5557"
              data-hover-text="+94 (77)398 5557"
            >
              +94 (77) 398 5557
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@tecciance.com"
              data-hover-text="info@tecciance.com"
            >
              info@tecciance.com
            </a>
          </p> */}
        </div>
      </div>

      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">USA</h5>
          <p>
            8 The Green STE R,
            <br /> Dover, DE 19901, <br />
            USA
          </p>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+94 (77)398 5557"
              data-hover-text="+94 (77)398 5557"
            >
              +1 (928) 326-8652
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@tecciance.com"
              data-hover-text="info@tecciance.com"
            >
              info@tecciance.com
            </a>
          </p>
        </div>

        {/* <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contact</h5> 
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+94 (77)398 5557"
              data-hover-text="+94 (77)398 5557"
            >
              925-497-9205
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@tecciance.com"
              data-hover-text="info@tecciance.com"
            >
              info@tecciance.com
            </a>
          </p> 
        </div> */}
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Follow us</h5>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
